export const DeviceTypes = {
  DESKTOP: 'desktop',
  ADAPTIVE: 'adaptive'
}

export const FeatureTypes = {
  LOCATION: 'LOCATION',
  DESIGN: 'DESIGN',
  PEOPLE: 'PEOPLE',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
}
