import React from 'react'
import css from './About.module.scss'
import image from 'assets/images/about.jpg'
import BlockWrapper from 'components/BlockWrapper/BlockWrapper'

const About = () => {
  return (
    <section id='about'>
      <BlockWrapper
        heading='Про нас'
      >
        <div className={css.wrapper}>
          <img
            className={css.image}
            src={image}
            alt='Панорама комплексу Triiinity'
          />
          <div className={css.description}>
            <p className={css.descriptor}>
              Завдяки команді професіоналів із&nbsp;досвідом втілення десятків масштабних проектів по&nbsp;усьому світу, SEVEN HILLS давно здобула статус надійного партнера та&nbsp;забудовника. Адже за&nbsp;15&nbsp;років роботи на&nbsp;українському ринку преміального житла, девелоперами було виконано усі взяті на&nbsp;себе зобов&rsquo;язання.
            </p>
            <p className={css.descriptor}>
              Проекти PARK AVENUE та&nbsp;PARK AVENUE VIP визнані кращими ЖК&nbsp;Східної Європи та&nbsp;Азії 2016-го і&nbsp;2018-го років на&nbsp;думку журі EEA Real Estate Forum &amp;&nbsp;Project Awards.
            </p>
            <p className={css.descriptor}>
              TRIIINITY&nbsp;&mdash; це&nbsp;третій проект девелоперської групи SEVEN HILLS, після успішно реалізованих PARK AVENUE та&nbsp;PARK AVENUE VIP. Кожен проект компанії є&nbsp;концептуальним і&nbsp;TRIIINITY не&nbsp;вийняток&nbsp;&mdash; комплекс уособлює триєдність стилю, технологій і&nbsp;природи. Основою для створення концепції стали три ключові елементи, які створюють гармонію: ваш будинок, ваші почуття і&nbsp;ви.
            </p>
          </div>
        </div>
      </BlockWrapper>
    </section>
  )
}

export default About
