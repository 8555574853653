import React from 'react'
import css from './EmployeeCard.module.scss'
import classnames from 'classnames'

const EmployeeCard = ({
  className,
  name,
  photo,
  rank,
  email,
  tag = 'li',
  style,
}) => {
  const TagName = tag

  return (
    <TagName
      className={classnames(css.wrapper, className)}
    >
      <span
        className={classnames(css.rank, {
          [css.ceo]: rank === 'Генеральний директор',
          [css.cm]: rank === 'Комерційний директор',
          [css.md]: rank === 'Керівник відділу маркетингу',
          [css.pm]: rank === 'Менеджер проекту' || rank === 'Адміністратор проекту',
          [css.adm]: rank === 'Адміністративний директор',
          [css.engineer]: rank === 'Головний інженер' ||
            rank === 'Інженер з проектно – кошторисної роботи' ||
            rank === 'Інженер технічного нагляду',
          [css.accountant]: rank === 'Бухгалтер',
          [css.fin]: rank === 'Коммерческий директор' || rank === 'Фінансовий менеджер',
          [css.manager]: rank === 'Менеджер адміністративної діяльності' || rank === 'Керівник проекту' || rank ===  'Заступник генерального директора',
          [css.it]: rank === 'Менеджер систем з інформаційної безпеки' || rank === 'Системний адміністратор',
          [css.law]: rank === 'Юрист',
          [css.td]: rank === 'Провідний інженер технічного нагляду',
          [css.sd]: rank === 'Менеджер із закупівель',
        })}
      >
        { rank }
      </span>
      <img
        className={css.photo}
        src={photo}
        alt={ `Фото ${name}` }
        style={{
          ...style
        }}
      />
      <span className={css.name}>
        { name }
      </span>
      <a
        href={ `mailto:${email}` }
        className={css.email}
      >
        { email }
      </a>
    </TagName>
  )
}

export default EmployeeCard
