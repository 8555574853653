import React from 'react'
import css from './Heading.module.scss'
import classnames from 'classnames'

const Heading = ({
  className,
  label,
  tag = 'h2',
  isDecorated
}) => {
  const TagName = tag

  return (
    <TagName
      className={classnames(css.heading, css[tag], className, {
        [css.headingDecorated]: isDecorated
      })}
    >
      { label }
    </TagName>
  )
}

export default Heading
