import React from 'react'
import MainBanner from 'components/MainBanner/MainBanner'
import About from 'components/About/About'
import Projects from 'components/Projects/Projects'
import Team from 'components/Team/Team'
import Contacts from 'components/Contacts/Contacts'

const Home = () => {
  return (
    <>
      <MainBanner />
      <About />
      <Projects />
      <Team />
      <Contacts />
    </>
  )
}

export default Home
