import {
  TOGGLE_MODAL
} from 'store/actions/actionTypes'
import { updateObject } from 'utils'

const initialState = {
  modal: {
    status: false,
    content: null,
    options: {}
  },
}

export function uiReducer (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return updateObject(state, { modal: {
        status: action.payload.status,
        content: action.payload.content,
        options: action.payload.options
      }})
    default: return state
  }
}
