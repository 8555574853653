import React from 'react'
import css from './BlockWrapper.module.scss'
import classnames from 'classnames'
import Container from 'components/Contaner/Container'
import Heading from 'components/Heading/Heading'

const BlockWrapper = ({ className, heading, children }) => {
  return (
    <Container
      className={classnames(css.wrapper, className)}
    >
      <Heading
        label={heading}
        isDecorated
      />
      <div className={css.content}>
        { children }
      </div>
    </Container>
  )
}

export default BlockWrapper
