import React, { useState } from 'react'
import css from './Projects.module.scss'
import classnames from 'classnames'
import BlockWrapper from 'components/BlockWrapper/BlockWrapper'
import SwiperCore, { Controller, Navigation, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import ProjectSlide from 'components/ProjectSlide/ProjectSlide'
import park1 from 'assets/images/park_1.jpg'
import park2 from 'assets/images/park_2.jpg'
import park3 from 'assets/images/park_3.jpg'
import park_vip1 from 'assets/images/park-vip_1.jpg'
import park_vip2 from 'assets/images/park-vip_2.jpg'
import park_vip3 from 'assets/images/park-vip_3.jpg'
import triiinity1 from 'assets/images/triiinity-1.jpg'
import triiinity2 from 'assets/images/triiinity-2.jpg'
import triiinity3 from 'assets/images/triiinity-3.jpg'
import { FeatureTypes } from 'utils/const'
import IconArrow from 'assets/icons/IconArrow'

SwiperCore.use([Controller, Navigation, EffectFade])

const Projects = () => {
  const [swiperController, setSwiperController] = useState(null)
  const [activeSlide, setActiveSlide] = useState(0)
  console.log(swiperController)

  return (
    <section id='projects'>
      <BlockWrapper
        className={css.wrapper}
        heading='Наші проекти'
      >
        <div className={css.slider}>
          <Swiper
            id='slider-projects'
            allowTouchMove={false}
            wrapperTag='ul'
            autoHeight={true}
            slidesPerView={1}
            onSwiper={setSwiperController}
            effect='fade'
            fadeEffect={{
              crossFade: true
            }}
            onSlideChange={swiper => {
                setActiveSlide(swiper.activeIndex)
            }}
            // navigation
          >
            <SwiperSlide>
              <ProjectSlide
                title='Park Avenue'
                address='м. Київ, просп. Голосіївский, 60'
                images={[park1, park2, park3]}
                feature ={ `Перший проект девелоперської групи в&nbsp;Україні. Розташування поруч з&nbsp;парком та&nbsp;у&nbsp;найзеленішому районі столиці, привабило багато родин приєднатися до&nbsp;комплексу, та&nbsp;вибрати його своєю домівкою. Закрита територія, автономність, безліч дитячих майданчиків та&nbsp;зон відпочинку&nbsp;&mdash; створило нову концепцію життєвого простору.` }
              />
            </SwiperSlide>
            <SwiperSlide>
              <ProjectSlide
                title='Park Avenue VIP'
                address='м. Київ, вул. Деміївська, 29'
                images={[park_vip1, park_vip2, park_vip3]}
                feature ={ `Продовження легендарного проекту Park Avenue, що&nbsp;був вдосконалений найсучаснішими технологіями та&nbsp;враховував побажання клієнтів компанії. Алюмінієві панорамні вікна, скляні ліфти, вишукане оздоблення вхідних групп&nbsp;&mdash; все це&nbsp;зробило проект виразливим на&nbsp;ринку преміального житла.` }
              />
            </SwiperSlide>
            <SwiperSlide>
              <ProjectSlide
                title='Triiinity'
                address='м. Київ, вул. Василя Тютюнника (Анрі Барбюса), 39/2'
                images={[triiinity1, triiinity2, triiinity3]}
                feature ={ `Ваш дім, ваші почуття та&nbsp;ви&nbsp;&mdash; це&nbsp;основа створення концепції нового проекту компанії, що&nbsp;розташований у&nbsp;Печерському районі столиці. TRIIINITY символізує триєдність, якою наповнений кожен елемент проекту. Технологічна досконалість, виразний стиль та&nbsp;деталізація кожного квадратного метра в&nbsp;проекті&nbsp;&mdash; створили новітній життєвий простір, що&nbsp;посів провідне місце серед проектів преміальної нерухомості.` }
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <button
          className={classnames(css.button, {
            [css.buttonDisabled]: activeSlide === 0
          })}
          type='button'
          onClick={() => swiperController.slidePrev()}
        >
          Prev
          <IconArrow className={css.icon} />
        </button>
        <button
          className={classnames(css.button, css.buttonNext, {
            [css.buttonDisabled]: activeSlide === 2
          })}
          type='button'
          onClick={() => swiperController.slideNext()}
        >
          Next
          <IconArrow className={css.icon} />
        </button>
      </BlockWrapper>
    </section>
  )
}

export default Projects
