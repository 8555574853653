export const debounce = func => {
  let lastTimeout = null

  return function () {
    const context = this
    const args = arguments

    if (lastTimeout) {
      clearTimeout(lastTimeout)
    }

    lastTimeout = setTimeout(function () {
      func.apply(context, args)
    }, 250)
  }
}

export const updateObject = (object, field) => Object.assign({}, object, field)

export const formatNumbers = (value, digits = 2) => {
  if (value > 999) {
    return String(+value.toFixed(digits)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')
  }

  return value
}

export const scrollToElement = (el, offset = 0) => {
  const targetOffset = el.getBoundingClientRect().top + window.scrollY

  window.scrollTo({
    top: targetOffset + offset,
    behavior: 'smooth'
  })
}

export const isEmailValid = value => {
  const valueArray = value.split('')
  const regexp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,12}$/

  return valueArray.length > 0
    ? valueArray.length >= 8 &&
    valueArray.length < 50 &&
    // в строке есть символ @
    valueArray.indexOf('@') !== -1 &&
    // в строке всего один символ @
    valueArray.indexOf('@') === valueArray.lastIndexOf('@') &&
    // в строке символ @ расположен так, что перед ним как минимум 2 символа
    valueArray.indexOf('@') > 1 &&
    // в строке символ @ расположен так, что после него как минимум 4 символов, например ab.ua
    valueArray.indexOf('@') < value.length - 4 &&
    // в строке есть точка
    valueArray.indexOf('.') !== -1 &&
    // в строке точка не первая
    valueArray.indexOf('.') > 0 &&
    // в строке последняя точка расположен так, что после нее как минимум 2 символа, например .ua
    valueArray.lastIndexOf('.') < value.length - 1 &&
    // в строке последняя точка находится раньше @
    valueArray.lastIndexOf('.') > valueArray.lastIndexOf('@') &&
    // в строке нет двух точек подряд
    value.indexOf('..') === -1 &&
    regexp.test(value)
    : true
}

export const validateName = () => ({
  required: true,
  validate: value => {
    return (`${value}`.length <= 50 && `${value}`.length >= 3) || `Пожалуйста, введите имя от 3 до 50 символов`
  },
  pattern: {
    value: /^[A-Za-zА-Яа-яёЁ\s']+$/,
    message: 'Имя может содержать лишь буквы латинского или кириллического алфавитов',
  },
})

export const validateEmail = () => ({
  validate: value => isEmailValid(value) || 'Проверьте правильность введенного адреса'
})

export const validatePhone = () => ({
  validate: {
    isPhone: value => {
      const purifiedString = `${value}`.split(' ').join('')
      return purifiedString === '' ||
        (!Number.isNaN(+purifiedString) && purifiedString.length >= 9 && purifiedString.length <= 20) ||
        `Проверьте корректность введенного номера телефона и убедитесь, что он не короче 9 и не длиннее 20 символов`
    }
  }
})
