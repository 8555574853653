import React from 'react'
import css from './Logo.module.scss'
import classnames from 'classnames'
import IconLogo from 'assets/icons/IconLogo'

const Logo = ({ className }) => {
  return (
    <div className={classnames(css.logoWrapper, className)}>
      <IconLogo className={css.logo} />
      <span className={css.logoLabel}>
        International Development Group
        </span>
    </div>
  )
}

export default Logo
