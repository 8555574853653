import React from 'react'
import css from './MainBanner.module.scss'
import classnames from 'classnames'
import Container from 'components/Contaner/Container'
import Heading from 'components/Heading/Heading'
import IconDots from 'assets/icons/IconDots'
import ButtonPlay from 'components/ButtonPlay/ButtonPlay'
import preview from 'assets/images/button-play__bg.jpg'
import { useDispatch } from 'react-redux'
import { toggleModal } from 'store/actions'
import video from 'assets/video/Park Avenue VIP.mp4'

const MainBanner = ({
  className,
  reference,
  ...props
}) => {
  const dispatch = useDispatch()

  const handleClickPlay = () => {
    dispatch(toggleModal(true, (
      <video className={css.video} autoPlay='autoplay' preload='preload'>
        <source src={video} />
      </video>
    )))
  }

  return (
    <section className={css.section}>
      <Container className={css.intro}>
        <ButtonPlay
          className={css.play}
          background={`url("${preview}")`}
          handleClick={handleClickPlay}
        />
      </Container>
      <div
        className={classnames(css.wrapper, className)}
        ref={reference}
        {...props}
      >
        <Container className={css.container}>
          <IconDots className={css.dots} />
          <Heading
            className={css.heading}
            label='Seven Hills '
            tag='h1'
          />
          <p className={css.descriptor}>
            International Development Group
          </p>
        </Container>
      </div>
    </section>
  )
}

export default MainBanner
