import React from 'react'

const IconLogo = ({ className }) => (
  <svg className={className} viewBox="0 0 221.21 98.27">
    <path d="M90.26,1.36c0,.76,9,1.38,20.11,1.38s20.11-.62,20.11-1.38S121.48,0,110.37,0,90.26.62,90.26,1.36Z" />
    <ellipse cx="45.85" cy="23.23" rx="45.01" ry="2.65" />
    <path d="M29.92,12.65c0,1.15,15.74,2.08,35.17,2.08s35.17-.93,35.17-2.08-15.75-2.07-35.17-2.07S29.92,11.51,29.92,12.65Z" />
    <ellipse cx="84.33" cy="6.09" rx="25.33" ry="1.52" />
    <ellipse cx="174.89" cy="23.23" rx="45.01" ry="2.65" />
    <path d="M190.82,12.65c0,1.15-15.74,2.08-35.17,2.08s-35.16-.93-35.16-2.08,15.74-2.07,35.16-2.07S190.82,11.51,190.82,12.65Z" />
    <ellipse cx="136.41" cy="6.09" rx="25.33" ry="1.52" />
    <ellipse cx="110.61" cy="70.83" rx="110.56" ry="2.41" />
      <path d="M11.62,32.23a10,10,0,0,1,3.89.67l1.48.63a1,1,0,0,0,.39.08.81.81,0,0,0,.52-.21l.3-.26a.29.29,0,0,1,.24-.09c.35,0,.54.34.54,1.06,0,.24.1,1,.28,2.17l.34,2.21a7.83,7.83,0,0,1,.11.88c0,.44-.16.67-.46.67s-.47-.35-.71-1a7.26,7.26,0,0,0-3.13-3.85,9.6,9.6,0,0,0-5.28-1.52A5.65,5.65,0,0,0,6.26,35,4.35,4.35,0,0,0,4.7,38.41a5,5,0,0,0,1,3.12,5.67,5.67,0,0,0,1.92,1.64A53.9,53.9,0,0,0,12.74,45q7.92,2.59,7.93,8.51a8.33,8.33,0,0,1-2.92,6.56,10.94,10.94,0,0,1-7.48,2.55,16,16,0,0,1-3.64-.47,9.22,9.22,0,0,1-2.9-1.09,1.31,1.31,0,0,1-.51-.54,11.3,11.3,0,0,1-.39-1.5c0-.12-.21-.77-.53-2a11.58,11.58,0,0,1-.56-2.76c0-.42.15-.63.45-.62s.5.37.71,1.06a8.37,8.37,0,0,0,3.24,4.5,8.87,8.87,0,0,0,5.37,1.82,7.37,7.37,0,0,0,5-1.74,5.39,5.39,0,0,0,2-4.25,5.12,5.12,0,0,0-1.88-4q-1-.85-5.56-2.39-4.76-1.61-6.73-3.56a6.78,6.78,0,0,1-2-5.05A7.08,7.08,0,0,1,5,34.42,10,10,0,0,1,11.62,32.23Z" />
      <path d="M30,51.5,30,52a9.38,9.38,0,0,0,2,6.13,6.2,6.2,0,0,0,5,2.48,8.67,8.67,0,0,0,5.09-2.08.7.7,0,0,1,.32-.15c.19,0,.3.1.3.34,0,.43-.5,1-1.5,1.81a9.44,9.44,0,0,1-5.89,2.17A7.57,7.57,0,0,1,29.48,60,10.06,10.06,0,0,1,29.56,47a7.41,7.41,0,0,1,5.72-2.79,6.74,6.74,0,0,1,5.73,3,11.09,11.09,0,0,1,1,2,5.33,5.33,0,0,1,.54,1.74c0,.47-.47.71-1.4.71l-1.58,0-1.46,0Zm.13-1.21h7.27c1.13,0,1.7-.23,1.7-.68a4.27,4.27,0,0,0-1.44-3.09,4.45,4.45,0,0,0-3.15-1.43,3.67,3.67,0,0,0-2.93,1.4A7,7,0,0,0,30.17,50.29Z" />
      <path d="M57,58.93l3.63-7.6a9.79,9.79,0,0,0,1.1-4c0-1-.68-1.59-2-1.82-.38-.05-.58-.19-.58-.42s.18-.45.55-.45a6.77,6.77,0,0,1,.79.06c.3,0,.81,0,1.55,0,1.47,0,2.43,0,2.92-.06l.79,0c.34,0,.5.12.49.36s-.32.39-.91.61a3.66,3.66,0,0,0-1.67,2l-7,14.61a.78.78,0,0,1-.71.54.85.85,0,0,1-.8-.61L48.9,47.93a4.34,4.34,0,0,0-2.32-2.5c-.29-.11-.45-.25-.45-.44s.22-.44.65-.44a3.38,3.38,0,0,1,.49,0,33.77,33.77,0,0,0,3.91.15c.87,0,1.49,0,1.83,0s.8-.06,1-.06c.33,0,.5.14.5.46s-.2.36-.6.41q-2,.26-2,1.24a3.81,3.81,0,0,0,.39,1.43Z" />
      <path d="M72.2,51.5l0,.47a9.39,9.39,0,0,0,2,6.13,6.22,6.22,0,0,0,5,2.48,8.63,8.63,0,0,0,5.08-2.08.75.75,0,0,1,.32-.15c.2,0,.3.1.3.34,0,.43-.5,1-1.5,1.81a9.42,9.42,0,0,1-5.9,2.17A7.54,7.54,0,0,1,71.64,60,10.05,10.05,0,0,1,71.71,47a7.4,7.4,0,0,1,5.73-2.79,6.7,6.7,0,0,1,5.72,3,10.8,10.8,0,0,1,1.06,2,5.41,5.41,0,0,1,.54,1.74c0,.47-.47.71-1.39.71l-1.59,0-1.46,0Zm.13-1.21h7.28c1.13,0,1.7-.23,1.7-.68a4.28,4.28,0,0,0-1.45-3.09,4.43,4.43,0,0,0-3.15-1.43,3.67,3.67,0,0,0-2.93,1.4A7,7,0,0,0,72.32,50.29Z" />
      <path d="M96.63,47.57q2.87-3.39,6-3.4A6.3,6.3,0,0,1,108,47c.91,1.29,1.36,3.24,1.36,5.87V58l0,.64.07,1a1.89,1.89,0,0,0,1.79,1.64l.89.13c.38.06.58.2.6.43s-.22.47-.71.47a7.14,7.14,0,0,1-.83-.09,19.44,19.44,0,0,0-2.61-.11l-2.12.06h-.81l-.63,0-.3-.07a.36.36,0,0,1-.15-.34c0-.22.13-.36.39-.42l.65-.17c.57-.14.86-.65.86-1.54V52.31a6.9,6.9,0,0,0-1.36-4.58,4.82,4.82,0,0,0-3.89-1.61,5.06,5.06,0,0,0-3.15,1,3,3,0,0,0-1.38,2.4v8.19l0,.64,0,1.07c.07,1.21,1.06,1.87,3,2,.47,0,.71.2.71.47s-.17.41-.5.41a6.82,6.82,0,0,1-1.09-.14c-.27,0-.95-.06-2-.06a38.52,38.52,0,0,0-4.07.11c-.45.06-.73.09-.86.09s-.4-.12-.39-.36.16-.32.41-.43a2.78,2.78,0,0,0,1.45-.93,3.2,3.2,0,0,0,.38-1.79V49.18a3.94,3.94,0,0,0-.36-2.06,1.55,1.55,0,0,0-1.38-.55l-.92,0a.69.69,0,0,1-.35-.09.32.32,0,0,1-.19-.31c0-.21.19-.35.57-.43a11.2,11.2,0,0,0,2.78-.89L95,44.32l.83-.41a1.1,1.1,0,0,1,.52-.17c.26,0,.37.21.35.62,0,.23,0,.5,0,.81Z" />
      <path d="M129.83,46.55h12.25a2.45,2.45,0,0,0,1.16-.17c.17-.11.25-.36.25-.74V38.57a6,6,0,0,0-.56-3,3,3,0,0,0-2-1.23c-.42-.08-.62-.24-.6-.48s.19-.35.52-.35.77,0,1.41.08c.92.07,1.89.11,2.93.11a28.37,28.37,0,0,0,3.13-.11,6.71,6.71,0,0,1,.77-.08c.33,0,.5.14.5.4s-.17.35-.5.42l-.85.19a1.62,1.62,0,0,0-1.08.93,3.67,3.67,0,0,0-.44,1.84V57.34a5.5,5.5,0,0,0,.62,3.07,3.31,3.31,0,0,0,2.4,1c.43.05.65.18.66.39s-.17.49-.58.49-.64,0-1.09-.05-1,0-2.08,0h-1.54l-1.57,0-1,0-1.44.05c-.35,0-.51-.13-.5-.41s.25-.4.71-.47q2.19-.37,2.19-3.55l0-.81V49.46c0-.66-.06-1-.19-1.15a2.52,2.52,0,0,0-1.24-.17H129.83a2.83,2.83,0,0,0-1.47.24c-.19.16-.29.57-.29,1.22v7.66a5.06,5.06,0,0,0,.69,3,3.62,3.62,0,0,0,2.56,1.08c.51.05.77.22.78.51s-.19.42-.61.42c-.18,0-.52,0-1-.09a21.33,21.33,0,0,0-2.5-.09l-1.74,0-1.37,0c-.35,0-.79,0-1.31.07-.72.06-1.16.09-1.31.09-.35,0-.52-.13-.52-.39s.18-.41.54-.49l1-.25a1.73,1.73,0,0,0,1.25-1.37,17.51,17.51,0,0,0,.33-4.09V37.84a4.07,4.07,0,0,0-.69-2.63,3.9,3.9,0,0,0-2.45-1.09c-.4-.05-.6-.2-.6-.43s.18-.41.54-.4.68.06,1.25.14a30.38,30.38,0,0,0,3.81.2,22.25,22.25,0,0,0,2.77-.16,6.76,6.76,0,0,1,.87-.09c.41,0,.61.14.61.42s-.19.36-.56.45a3.22,3.22,0,0,0-1.87,1,3.78,3.78,0,0,0-.49,2.2v7.43c0,.79.08,1.27.24,1.42A2.89,2.89,0,0,0,129.83,46.55Z" />
      <path d="M164.49,58.73a4.15,4.15,0,0,0,.32,2,1.87,1.87,0,0,0,1.36.65l.95.11c.5.07.73.24.72.53s-.19.35-.56.35-.68,0-1.35-.09c-.37,0-.86,0-1.45,0l-1.33,0-1.51.06c-.28,0-.49,0-.65,0l-.74,0c-.38,0-.56-.13-.56-.39a.45.45,0,0,1,.37-.44l.39-.13a1.49,1.49,0,0,0,.92-.79,4.51,4.51,0,0,0,.26-1.81v-9a4.86,4.86,0,0,0-.34-2.24c-.22-.35-.7-.52-1.44-.52-.53,0-.79-.11-.78-.32s.37-.38,1.06-.59a22.5,22.5,0,0,0,3-1.33l1.11-.52c.19,0,.29.18.29.51Z" />
      <path d="M180.41,32.92V57.38a6.88,6.88,0,0,0,.39,2.62q.46,1,2.58,1.4c.4.07.6.21.61.42a.39.39,0,0,1-.44.46,2.11,2.11,0,0,1-.54-.1,5,5,0,0,0-.94-.06l-1.81,0h-1.09c-1.32,0-2.13,0-2.44.06l-.79.09c-.37,0-.55-.14-.57-.41s.18-.4.58-.51a2.26,2.26,0,0,0,1.26-.71,2.53,2.53,0,0,0,.32-1.44v-23c0-1.28-.44-1.92-1.33-1.92l-1,0a1.22,1.22,0,0,1-.35,0,.35.35,0,0,1-.25-.34c0-.19.18-.35.54-.45a8.71,8.71,0,0,0,1.29-.39l1.27-.52a10.42,10.42,0,0,0,2-1,.85.85,0,0,1,.42-.17c.2,0,.31.29.31.92Z" />
      <path d="M196.34,32.92V57.38a6.71,6.71,0,0,0,.4,2.62c.3.7,1.17,1.16,2.58,1.4.39.07.59.21.61.42a.38.38,0,0,1-.44.46,2.08,2.08,0,0,1-.54-.1,4.94,4.94,0,0,0-.94-.06l-1.81,0h-1.09c-1.32,0-2.13,0-2.45.06l-.79.09c-.37,0-.55-.14-.56-.41s.18-.4.58-.51a2.24,2.24,0,0,0,1.26-.71,2.51,2.51,0,0,0,.32-1.44v-23c0-1.28-.43-1.92-1.32-1.92l-1,0a1.18,1.18,0,0,1-.35,0,.35.35,0,0,1-.25-.34c0-.19.18-.35.54-.45a8.49,8.49,0,0,0,1.29-.39l1.27-.52a10.62,10.62,0,0,0,2-1,.84.84,0,0,1,.41-.17c.21,0,.31.29.31.92Z" />
      <path d="M212.8,44.66a8.35,8.35,0,0,1,2.84.43l.92.3c.37.13.57.35.57.62v.34a4,4,0,0,0,0,.58l.09.81a5,5,0,0,1,.07.8c0,.54-.16.82-.49.83-.17,0-.33-.16-.49-.54A4.28,4.28,0,0,0,212,45.68a3,3,0,0,0-2.06.71,2.4,2.4,0,0,0,.08,3.66,15.06,15.06,0,0,0,3.51,1.73,9.77,9.77,0,0,1,3.62,2.15,4.41,4.41,0,0,1,1,3,5.35,5.35,0,0,1-1.74,4,5.77,5.77,0,0,1-4.16,1.67,8.67,8.67,0,0,1-3.74-1,2.16,2.16,0,0,0-.6-.23l-.55-.06a.51.51,0,0,1-.52-.51l0-.89-.07-2.64,0-.62c.06-.37.21-.57.46-.58s.43.19.52.62a6.75,6.75,0,0,0,1.7,3.58,4.11,4.11,0,0,0,3,1.28,3.52,3.52,0,0,0,2.5-.9,3,3,0,0,0,1-2.3,3.39,3.39,0,0,0-2.36-3.18l-1.83-.82a10.24,10.24,0,0,1-3.55-2.23,4,4,0,0,1-1-2.81A4.25,4.25,0,0,1,208.83,46,5.91,5.91,0,0,1,212.8,44.66Z" />
    <path d="M165.4,38.24a2.56,2.56,0,1,1-2.56-2.57A2.56,2.56,0,0,1,165.4,38.24Z"/>
  </svg>
)

export default IconLogo
