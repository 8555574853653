import {
  TOGGLE_MODAL
} from 'store/actions/actionTypes'

export const toggleModal = (status, content, options) => {
  return {
    type: TOGGLE_MODAL,
    payload: {
      status,
      content,
      options
    }
  }
}
