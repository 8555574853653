import React, { useState } from 'react'
import css from './Team.module.scss'
import BlockWrapper from 'components/BlockWrapper/BlockWrapper'
import diveeva from 'assets/images/diveeva.jpg'
import boklan from 'assets/images/boklan.jpg'
import ansimov from 'assets/images/ansimov.jpg'
import semashko from 'assets/images/semashko.jpg'
import rul from 'assets/images/rul.jpg'
import velickko from 'assets/images/velichko.jpg'
import pedchenko from 'assets/images/pedchenko.jpg'
import antypenko from 'assets/images/antypenko.jpg'
import tyschenko from 'assets/images/tyschenko.jpg'
import bazarna from 'assets/images/bazarna.jpg'
import musienko from 'assets/images/musienko.jpg'
import klyots from 'assets/images/klyots.jpg'
import ponomareva from 'assets/images/ponomareva.jpg'
import sivinuk from 'assets/images/sivinuk.jpg'
import volkov from 'assets/images/volkov.jpg'
import kachura from 'assets/images/kachura.jpg'
import maksimyak from 'assets/images/maksimyak.jpg'
import derkachev from 'assets/images/derkachev.jpg'
import pankiv from 'assets/images/pankiv.jpg'
import redka from 'assets/images/redka.jpg'
import EmployeeCard from 'components/EmployeeCard/EmployeeCard'

const Team = () => {
  const team = [
    {
      name: 'Наталія Дівєєва',
      photo: diveeva,
      rank: 'Генеральний директор',
      email: 'info@sevenhills.com.ua',
    },
    {
      name: 'Лариса Боклан',
      photo: boklan,
      rank: 'Заступник генерального директора',
      email: 'l.boklan@sevenhills.com.ua'
    },
    {
      name: 'Владислав Максим‘як',
      photo: maksimyak,
      rank: 'Комерційний директор',
      email: 'v.maksymiak@sevenhills.com.ua'
    },
    {
      name: 'Людмила Руль',
      photo: rul,
      rank: 'Керівник відділу маркетингу',
      email: 'l.rul@sevenhills.com.ua'
    },
    {
      name: 'Світлана Антипенко',
      photo: antypenko,
      rank: 'Бухгалтер',
      email: 's.antypenko@sevenhills.com.ua'
    },
    {
      name: 'Олена Тищенко',
      photo: tyschenko,
      rank: 'Бухгалтер',
      email: 'o.tishchenko@sevenhills.com.ua'
    },
    {
      name: 'Катерина Базарна',
      photo: bazarna,
      rank: 'Фінансовий менеджер',
      email: 'k.bazarna@sevenhills.com.ua'
    },
    {
      name: 'Олександра Мусієнко',
      photo: musienko,
      rank: 'Менеджер адміністративної діяльності',
      email: 'o.musienko@sevenhills.com.ua'
    },
    {
      name: 'Олександр Кльоц',
      photo: klyots,
      rank: 'Менеджер систем з інформаційної безпеки',
      email: 'o.klots@sevenhills.com.ua'
    },
    {
      name: 'Анна Пономарьова',
      photo: ponomareva,
      rank: 'Юрист',
      email: 'a.ponomarova@sevenhills.com.ua'
    },
    {
      name: 'Євген Сивинюк',
      photo: sivinuk,
      rank: 'Менеджер систем з інформаційної безпеки',
      email: 'y.syvyniuk@sevenhills.com.ua'
    },
    {
      name: 'Володимир Волков',
      photo: volkov,
      rank: 'Системний адміністратор',
      email: 'v.volkov@sevenhills.com.ua'
    },
    {
      name: 'Олександр Ансімов',
      photo: ansimov,
      rank: 'Адміністратор проекту',
      email: 'o.ansimov@sevenhills.com.ua'
    },
    {
      name: 'Юрій Величко',
      photo: velickko,
      rank: 'Керівник проекту',
      email: 'y.velychko@sevenhills.com.ua'
    },
    {
      name: 'Олена Качура',
      photo: kachura,
      rank: 'Інженер з проектно – кошторисної роботи',
      email: 'o.kachura@sevenhills.com.ua'
    },
    {
      name: 'Дмитро Деркачев',
      photo: derkachev,
      rank: 'Інженер технічного нагляду',
      email: 'd.derkachov@sevenhills.com.ua',
    },
    {
      name: 'Володимир Педченко',
      photo: pedchenko,
      rank: 'Провідний інженер технічного нагляду',
      email: 'v.pedchenko@sevenhills.com.ua'
    },
    {
      name: 'Юрій Паньків',
      photo: pankiv,
      rank: 'Інженер технічного нагляду',
      email: 'y.pankiv@sevenhills.com.ua'
    },
    {
      name: 'Сергій Редька',
      photo: redka,
      rank: 'Менеджер із закупівель',
      email: 's.redka@sevenhills.com.ua'
    },
  ]

  const [size, setSize] = useState(4)

  const handleClickMore = () => {
    setSize(prevSize => prevSize + 4)
  }

  return (
    <section id='team'>
      <BlockWrapper
        heading='Наша команда'
      >
        <ul className={css.list}>
          {team.slice(0, size).map((item, index) => (
            <React.Fragment key={index}>
              <EmployeeCard {...item} />
            </React.Fragment>
          ))}
        </ul>
        {size < team.length &&
          <button
            className={css.buttonMore}
            onClick={handleClickMore}
            type='button'
          >
            Показати ще
          </button>
        }
      </BlockWrapper>
    </section>
  )
}

export default Team
