import React, { useState } from 'react'
import css from './Header.module.scss'
import Container from 'components/Contaner/Container'
import Logo from 'components/Logo/Logo'
import Navigation from 'components/Navigation/Navigation'
import IconPhone from 'assets/icons/IconPhone'
import IconLocation from 'assets/icons/IconLocation'
import IconMail from 'assets/icons/IconMail'
import IconBurger from 'assets/icons/IconBurger'

const Header = () => {
  const [isCollapseOpened, setCollapseStatus] = useState(false)

  return (
    <header className={css.header}>
      <div className={css.contacts}>
        <Container className={css.contactsWrapper}>
          <p className={css.phone}>
            <IconPhone className={css.icon} />
            <a
              href="tel:+38 044 503 70 80"
              className={css.link}
            >
              +38 044 503 70 80
            </a>
          </p>
          <p className={css.email}>
            <IconMail className={css.icon} />
            <a
              href="mailto:info@sevenhills.com.ua"
              className={css.link}
            >
              info@sevenhills.com.ua
            </a>
          </p>
          <p className={css.location}>
            <IconLocation className={css.icon} />
            Київ, Голосіївский проспект, 58А
          </p>
        </Container>
      </div>
      <Container className={css.wrapper}>
        <Logo />
        <button
          className={css.buttonCollapse}
          onClick={() => setCollapseStatus(state => !state)}
          type='button'
        >
          Toggle collapse
          <IconBurger className={css.burger} />
        </button>
        <Navigation
          className={css.nav}
          isOpened={isCollapseOpened}
        />
      </Container>
    </header>
  )
}

export default Header
