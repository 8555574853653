import React, { useCallback, useEffect, useRef } from 'react'
import css from './withModal.module.scss'
import classnames from 'classnames'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useDispatch, useSelector } from 'react-redux'
import { toggleModal } from 'store/actions'
import IconClose from 'assets/icons/IconClose'

const withModal = WrappedComponent => {
  return props => {
    const modalRef = useRef(null)
    const modal = useSelector(state => state.ui.modal)
    const dispatch = useDispatch()
    const { status, content } = modal

    const createModalRef = node => modalRef.current = node

    const handleEscPress = useCallback(evt => {
      if (evt.key === 'Escape') {
        dispatch(toggleModal(false, null, {
          isCloseBtnRequired: false
        }))
      }
    }, [dispatch])

    useEffect(() => {
      if (status && modalRef.current) {
        disableBodyScroll(modalRef.current, {
          reserveScrollBarGap: true,
        })
      } else {
        clearAllBodyScrollLocks()
      }
    }, [status])

    useEffect(() => {
      document.addEventListener('keydown', handleEscPress)

      return () => {
        document.removeEventListener('keydown', handleEscPress)
      }
    }, [handleEscPress])

    const handleClickClose = () => {
      dispatch(toggleModal(false, null))
    }

    return (
      <>
        <div
          className={classnames(css.wrapper, {
            [css.wrapperOpened]: status
          })}
          ref={createModalRef}
        >
          <div className={css.content}>
            <button
              onClick={handleClickClose}
              className={css.button}
              type='button'
            >
              Close modal
              <IconClose className={css.icon} />
            </button>
            { content }
          </div>
        </div>
        <WrappedComponent {...props} />
        </>
    )
  }
}

export default withModal
