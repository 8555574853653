import React from 'react'
import css from './Contacts.module.scss'
import Container from 'components/Contaner/Container'
import office from 'assets/images/contacts__img.jpg'

const Contacts = () => {
  return (
    <section id='contacts'>
      <Container className={css.wrapper}>
        <img
          className={css.img}
          src={office}
          alt='Seven Hills office'
        />
        <div className={css.details}>
          <p className={css.address}>
            03039, Україна, Київ, Голосіївский проспект, 58А
          </p>
          <a
            href='tel:+380445017080'
            className={css.link}
          >
            +38 044 503 70 80
          </a>
          <a
            href='mailto:info@sevenhills.com.ua'
            className={css.link}
          >
            info@sevenhills.com.ua
          </a>
        </div>
      </Container>
    </section>
  )
}

export default Contacts
