import React from 'react'
import css from './Navigation.module.scss'
import { HashLink as Link } from 'react-router-hash-link'
import { Collapse } from 'react-collapse'
import { isMobileOnly } from 'react-device-detect'

const Navigation = ({ className, isOpened }) => {
  return (
    <div className={className}>
      <Collapse
        isOpened={isMobileOnly ? isOpened : true}
      >
        <nav>
          <ul className={css.list}>
            <li className={css.item}>
              <Link
                to='#about'
                className={css.link}
              >
                Про нас
              </Link>
            </li>
            <li className={css.item}>
              <Link
                to='#projects'
                className={css.link}
              >
                Проекти
              </Link>
            </li>
            <li className={css.item}>
              <Link
                to='#team'
                className={css.link}
              >
                Наша команда
              </Link>
            </li>
            <li className={css.item}>
              <Link
                to='#contacts'
                className={css.link}
              >
                Контакти
              </Link>
            </li>
          </ul>
        </nav>
      </Collapse>
    </div>
  )
}

export default Navigation
