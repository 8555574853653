import React from 'react'
import Header from 'components/Header/Header'
import Home from 'Pages/Home/Home'
import Footer from 'components/Footer/Footer'

const DesktopApp = () => {
  return (
    <>
      <Header />
      <main>
        <Home />
      </main>
      <Footer />
    </>
  )
}

export default DesktopApp
