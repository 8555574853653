import React from 'react'
import css from './ProjectSlide.module.scss'
import { useDispatch } from 'react-redux'
import { toggleModal } from 'store/actions'
import Heading from 'components/Heading/Heading'
import IconLogo from 'assets/icons/IconLogo'

const ProjectSlide = ({ title, address, images, feature }) => {
  const dispatch = useDispatch()

  const handleClickPreview = image => {
    dispatch(toggleModal(true, (
      <img
        className={css.full}
        src={image}
        alt='Seven Hills project'
      />
    )))
  }

  const renderPreviews = () => images.map((image, index) => (
    <li className={css.item} key={index}>
      <button
        style={{
          backgroundImage: `url("${image}")`
        }}
        onClick={() => handleClickPreview(image)}
        className={css.preview}
        type='button'
      >
        Show full image
      </button>
    </li>
  ))

  return (
    <div className={css.wrapper}>
      <Heading
        label={title}
        tag='h3'
      />
      <p
        className={css.address}
        dangerouslySetInnerHTML={{ __html: address }}
      />
      <ul className={css.list}>
        { renderPreviews() }
      </ul>
      <p
        className={css.feature}
      >
        <IconLogo className={css.icon} />
        <span dangerouslySetInnerHTML={{ __html: feature }} />
      </p>
    </div>
  )
}

export default ProjectSlide
