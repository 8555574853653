import React from 'react'

const IconBurger = ({ className }) => (
  <svg className={className} viewBox='0 0 15 14'>
    <rect x='0.5' y='0.5' width='14' height='1' />
    <rect x='0.5' y='12.5' width='14' height='1' />
    <rect x='0.5' y='6.5' width='9' height='1' />
  </svg>)

export default IconBurger
