import React from 'react'
import css from './Footer.module.scss'
import Container from 'components/Contaner/Container'
import IconLogo from 'assets/icons/IconLogo'

const Footer = () => {
  return (
    <footer className={css.footer}>
      <Container className={css.wrapper}>
        <IconLogo className={css.logo} />
        <span className={css.year}>
          { new Date().getFullYear() }
        </span>
      </Container>
    </footer>
  )
}

export default Footer
